import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes';

class PageRoutes extends React.Component {
  
  render() {
    return (
      <Routes>
        <Route {...ROUTES.STUDENT_DASHBOARD} />
        <Route {...ROUTES.AUTH} />
        <Route {...ROUTES.VIEW_CLASS} />
      </Routes>
    );
  }
}

export default PageRoutes;
