import axios from "axios";
import { getSessionStorage, setSessionStorage } from "../utilities";

const API_URL = process.env.REACT_APP_API_ROOT;
let guestToken = null;

// Function to fetch guest token
const fetchGuestToken = async () => {
  try {
    const response = await axios.get(`${API_URL}/user/guest/fetch-access-token`);
    guestToken = response.data.body.token; // Store the token in memory
    setSessionStorage('guestToken', guestToken);
    console.log('Guest token fetched successfully:', guestToken);
  } catch (error) {
    console.error('Error fetching guest token:', error);
    guestToken = null;
  }
};

// Function to get the current guest token
export const getGuestToken = async () => {
  guestToken = getSessionStorage('guestToken');
  if (!guestToken) {
    await fetchGuestToken();
  }
  return guestToken;
};
