import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes/routes';
import ContentLoader from './components/ContentLoader';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Suspense fallback={<ContentLoader />}>
          <Routes>
            <Route {...ROUTES.PAGE_ROUTES} />
          </Routes>
        </Suspense>
      </Router>
    );
  }
}

export default App;
