import { lazy } from 'react';
import { API_URL } from './constants';
import axios from 'axios';
import { getGuestToken } from './actions/tokenService';

export function retry(fn, retriesLeft = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (retriesLeft === 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function load(component) {
    const LazyComponent = lazy(() => retry(() => import(`./components/${component}`)));
  
    // Return a Suspense-wrapped JSX element
    return (props) => (
      <LazyComponent {...props} />
    );
}

export async function fetchTimeZones() {
  const token = await getGuestToken();

  return axios({
    method: "GET",
    url: `${API_URL}/auxiliary/timezones`,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

export function timeZoneFormat(timezones) {
  var offset = 0;
  var z = 0;
  timezones.forEach((obj, index) => {
    offset = obj.offsetInMinutes;
    z = `${offset < 0 ? "-" : "+"}${pad(
      parseInt(Math.abs(offset / 60)),
      2
    )}:${pad(Math.abs(offset % 60), 2)}`;
    obj.utcTimeForm = obj.text + " (UTC " + z + ")";
  });
  return timezones;
}

export function pad(s, size) {
  s = s.toString();
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

export const setLocalStorage = (itemName, itemValue) => {
  if (typeof itemValue === "object") {
    itemValue = JSON.stringify(itemValue);
  }
  localStorage.setItem(itemName, itemValue);
};

export function getLocalStorage(itemName) {
  let result = null;
  const item = localStorage.getItem(itemName);
  try {
    result = JSON.parse(item);
  } catch (e) {
    result = item;
  }
  return result;
}

export function setSessionStorage(itemName, itemValue) {
  if (typeof itemValue === "object") {
    itemValue = JSON.stringify(itemValue);
  }
  sessionStorage.setItem(itemName, itemValue);
}

export function getSessionStorage(itemName) {
  let result = null;
  const item = sessionStorage.getItem(itemName);
  try {
    result = JSON.parse(item);
  } catch (e) {
    result = item;
  }
  return result;
}

export function validateEmail(email) {
  let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  if (email.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
};

export function validateNumber(inputNumber) {
  let regexNumber = /^[0-9\b]+$/;
  if (inputNumber.match(regexNumber)) {
    return true;
  } else {
    return false;
  }
};

export function validatePassword(password) {
  let regexPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  if (password.match(regexPassword)) {
    return true;
  } else {
    return false;
  }
}