import { load } from '../utilities';
import PageRoutes from './PageRoutes';

export const ROUTES = {
  PAGE_ROUTES: {
    path: '/*',
    element: <PageRoutes />
  },
  STUDENT_DASHBOARD: {
    path: '/',
    exact: true,
    element: load('dashboard/StudentDashboard')()
  },
  AUTH: {
    path: '/auth',
    exact: true,
    element: load('user/SignupLogin')()
  },
  VIEW_CLASS: {
    path: '/view-class/:classId',
    exact: true,
    element: load('class/ViewClass')()
  },
};